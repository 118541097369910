<template>
  <div v-if="videoUrl">
    <div :id="id"></div>
  </div>
</template>

<script>
export default {
  name: "player-replay",
  components: {},
  props: {
    videoUrl: null,
    videoId: null
  },
  data() {
    return {
      id: "player-brahms-" + this.videoId
    }
  },
  computed: {},
  mounted() {
    if (this.videoUrl) {
      this.loadJwplayer()
    }
  },
  methods: {
    getGtmData() {
      return {
        video_id: "88888888",
        video_name: window.location.pathname,
        video_category: "others",
        video_type: "static",
        video_url: window.location.pathname
      }
    },
    getAudienceData() {
      let context = {}
      if (this.$store.state.viewerCountry) {
        context.country_code = this.$store.state.viewerCountry
      }
      if (this.$store.state.viewerCity) {
        context.city = this.$store.state.viewerCity
      }
      if (this.$store.state.viewerCountryName) {
        context.country = this.$store.state.viewerCountryName
      }
      return {
        audience: {
          id: "88888888",
          slug: window.location.pathname,
          type: "static",
          category: "others"
        },
        context: context
      }
    },
    loadJwplayer() {
      if (window.jwplayer) {
        this.$store.commit("set_player", this.id)
        this.loadStaticVideo()
      } else {
        let script = document.createElement("script")
        script.onload = () => {
          this.$store.commit("set_player", this.id)
          this.loadStaticVideo()
        }
        script.onerror = () => {
          console.error("jwaplyer init error, id: " + this.id)
        }
        script.src = "https://api.medici.tv/proxy-jwplayer/cdnjwplayer/libraries/A3dE0fZq.js"
        document.head.appendChild(script)
      }
    },
    loadStaticVideo() {
      const additionalSettings = {
        playlist: [{ file: this.videoUrl }]
      }
      let movieGtmData = this.getGtmData()
      let movieAudienceData = this.getAudienceData()
      this.$store.commit("start_player", {
        additionalSettings,
        movieGtmData,
        movieAudienceData
      })
    }
  }
}
</script>

<style lang="scss"></style>
